import ptBR from 'locales/pt-BR.json'
import enUS from 'locales/en-US.json'
import deDE from 'locales/de-DE.json'

const locales = {
    'pt-BR': ptBR,
    'pt-PT': ptBR,
    'pt-T': ptBR,
    'pt': ptBR,
    'en-US': enUS,
    'en-029': enUS,
    'en-AU': enUS,
    'en-BZ': enUS,
    'en-CA': enUS,
    'en-IE': enUS,
    'en-IN': enUS,
    'en-JM': enUS,
    'en-MY': enUS,
    'en-NZ': enUS,
    'en-PH': enUS,
    'en-UK': enUS,
    'en-ZW': enUS,
    'en': enUS,
    'de-DE': deDE,
    'de-CH': deDE,
    'de-LI': deDE,
    'de-LU': deDE,
    'de': deDE
}

export const languageCodesPt = [
    'pt-BR',
    'pt-PT',
    'pt-T',
    'pt',
  ]

export const languageCodesEn = [
    'en-US',
    'en-029',
    'en-AU',
    'en-BZ',
    'en-CA',
    'en-IE',
    'en-IN',
    'en-JM',
    'en-MY',
    'en-NZ',
    'en-PH',
    'en-UK',
    'en-ZW',
    'en'
  ]
  
export const languageCodesDe = [
    'de-DE',
    'de-CH',
    'de-LI',
    'de-LU',
    'de'
  ]

export default locales