import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { TranslationContainer } from 'react-i18n-lite'

import locales from 'locales'
import LoadingSpinner from 'components/LoadingSpinner';

import reportWebVitals from 'reportWebVitals';

const App = lazy(() => import('App'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <TranslationContainer locales={locales} defaultLanguage="pt-BR">
        <Suspense fallback={<LoadingSpinner />}>
          <App />
        </Suspense>
      </TranslationContainer>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
